// @flow
import { put, select, take } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import { LOCATION_CHANGE, push } from 'connected-react-router';
import { NOT_FOUND_PATH, REGISTER_PATH } from 'shared/constants/paths';
import { pathnameSelector, querySelector } from './routerSelectors';
import { entityOccurrenceIdSelector } from 'redux/modules/entity/entitySelectors';
import { getEvent } from 'redux/modules/event/eventActionCreators';
import { getCourse } from 'redux/modules/course/courseActionCreators';
import { getOccurrence } from 'redux/modules/occurrence/occurrenceActionCreators';

const mapPaths: Object = {
  [REGISTER_PATH]: {
    idIndex: 3
  }
};

const getId = (pathname: string): string => {
  const paths = pathname.split('/');
  const basePath: string = paths.length ? paths[1] : '';
  return mapPaths.hasOwnProperty(basePath)
    ? paths[mapPaths[basePath].idIndex]
    : '';
};

const getOid = (query: ?{ [string]: string }): string => {
  return !!query && query.hasOwnProperty('oid') ? query.oid : '';
};

export function* watchRouter(): Saga<void> {
  for (;;) {
    // previous values
    const entityId = yield select(entityOccurrenceIdSelector);
    yield take(LOCATION_CHANGE);
    const pathname = yield select(pathnameSelector);
    const query = yield select(querySelector);
    const paths = pathname.split('/');
    const basePath: string = paths.length ? paths[1] : '';
    const id = getId(pathname);
    const oid = getOid(query);
    let notFound = false;

    switch (basePath) {
      case REGISTER_PATH:
        if (!id) {
          notFound = true;
        } else if (!!id && id !== entityId) {
          if (oid) {
            // fetch course
            yield put(getCourse(id));
            // fetch occurrence
            yield put(getOccurrence(oid));
          } else {
            // fetch event
            yield put(getEvent(id));
          }
        }
        break;
      default:
    }

    // Redirect to Not Found
    if (notFound) {
      yield put(push(NOT_FOUND_PATH));
    }
  }
}
