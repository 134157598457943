// @flow
import React from 'react';
import type { Node } from 'react';

import Header from './Header';
import Footer from './Footer';

import styles from './DefaultLayout.module.scss';

type Props = {
  children: Node
};

const DefaultLayout = ({ children }: Props): Node => (
  <div className={styles.container}>
    <div className={styles.content}>{children}</div>
    <Header />
    <Footer />
  </div>
);

DefaultLayout.displayName = 'DefaultLayout';

export default DefaultLayout;
