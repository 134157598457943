// @flow
import React from 'react';
import type { Node } from 'react';

import styles from './Footer.module.scss';

const Footer = (): Node => (
  <header className={styles.footer}>
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.usconcealedcarry.com"
    >
      © {new Date().getFullYear()} USCCA
    </a>
  </header>
);

Footer.displayName = 'Footer';

export default Footer;
