// @flow
import { fork, put, take } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';

import { FETCH_SUCCESS as SET_USER } from 'redux/modules/user/userActions';
import { SET_VALUE, validateForm } from './checkout';

export function* watchSetUser(): Saga<void> {
  for (;;) {
    yield take(SET_USER);
    yield put(validateForm());
  }
}

export function* watchSetValue(): Saga<void> {
  for (;;) {
    yield take(SET_VALUE);
    yield put(validateForm());
  }
}

export default function* checkoutSaga(): Saga {
  yield fork(watchSetUser);
  yield fork(watchSetValue);
}
