// @flow

export const GET_ME_QUERY = `
{
  me {
    id
    name
    surname
    email
    phone
  }
}
`;

export type ME = {
  id: string,
  name: string,
  surname: string,
  email: string,
  phone: ?string
};
