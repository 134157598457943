// @flow
import { CALL_API } from 'redux/middleware/sagas/api';
import type { CallApiReturnI } from 'redux/middleware/sagas/api';
import type { ActionI } from 'redux/interfaces/ActionI';
import type { ErrorsI } from 'redux/interfaces/mesh/ErrorI';
import createPassword from 'shared/utils/createPassword';
import {
  SET_IS_AUTHED,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  FETCH_ERROR,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  CREATE_ERROR,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_ERROR,
  SET_BLURRED,
  SET_VALUE
} from './userActions';
// mesh
import { GET_ME_QUERY } from './userQueries';
import type { ME } from './userQueries';
import {
  CREATE_USER_MUTATION,
  UPDATE_PASSWORD_MUTATION
} from './userMutations';
import type { NEW_USER } from './userMutations';

export const setIsAuthed = (): ActionI => ({
  type: SET_IS_AUTHED
});

export const getUserRequest = (): ActionI => ({
  type: FETCH_REQUEST
});

type UserJson = {
  data: {
    me: ME,
    id: string,
    type: string
  }
};

export type ReceiveUserReturn = {
  payload: UserJson,
  meta: {
    receivedAt: number
  }
} & ActionI;

export const receiveUser = (json: UserJson): ReceiveUserReturn => ({
  type: FETCH_SUCCESS,
  payload: json,
  meta: {
    receivedAt: Date.now()
  }
});

export type GetUserErrorReturn = {
  error: ErrorsI
} & ActionI;

export const getUserError = (error: ErrorsI): GetUserErrorReturn => ({
  type: FETCH_ERROR,
  error,
  meta: {
    receivedAt: Date.now()
  }
});

export const getUser = (): CallApiReturnI => ({
  type: CALL_API,
  payload: {
    method: 'POST',
    endpoint: '/api/mesh/',
    options: {
      query: GET_ME_QUERY,
      variables: {}
    },
    actions: {
      request: getUserRequest,
      success: receiveUser,
      failure: getUserError
    }
  }
});

export const createUserRequest = (): ActionI => ({
  type: CREATE_REQUEST
});

type NewUserJson = {
  data: {
    createUserIdentity: {
      id: string,
      userToken: { token: string, ttl: number }
    }
  }
};

export type CreateUserSuccessReturn = {
  payload: {
    data: {
      createUserIdentity: NEW_USER
    }
  },
  meta: {
    receivedAt: number
  }
} & ActionI;

export const createUserSuccess = (
  json: NewUserJson
): CreateUserSuccessReturn => ({
  type: CREATE_SUCCESS,
  payload: json,
  meta: {
    receivedAt: Date.now()
  }
});

export type CreateUserErrorReturn = {
  error: ErrorsI
} & ActionI;

export const createUserError = (error: ErrorsI): CreateUserErrorReturn => ({
  type: CREATE_ERROR,
  error,
  meta: {
    receivedAt: Date.now()
  }
});

export type NewUser = {
  name: string,
  surname: string,
  email: string,
  phone?: string
};

export const createUser = (user: NewUser, tID: string): CallApiReturnI => ({
  type: CALL_API,
  payload: {
    method: 'POST',
    endpoint: '/api/mesh/',
    options: {
      query: CREATE_USER_MUTATION,
      variables: {
        input: {
          ...user,
          password: createPassword(),
          tracking: {
            tID: tID,
            origin: '-',
            medium: '-',
            pageType: '-',
            audience: '-',
            campaign: '-',
            targeting: '-',
            type: '-'
          }
        }
      }
    },
    actions: {
      request: createUserRequest,
      success: createUserSuccess,
      failure: createUserError
    }
  }
});

export type GetPasswordErrorReturn = {
  error: ErrorsI
} & ActionI;

export const getPasswordError = (error: ErrorsI): GetPasswordErrorReturn => ({
  type: UPDATE_PASSWORD_ERROR,
  error,
  meta: {
    receivedAt: Date.now()
  }
});

type PasswordJson = {
  data: {
    id: string,
    type: string
  }
};

type ReceivePasswordReturn = {
  payload: PasswordJson,
  meta: {
    receivedAt: number
  }
} & ActionI;

type UpdateParams = {
  password: string,
  id: string
};

export const updatePasswordRequest = (): ActionI => ({
  type: UPDATE_PASSWORD_REQUEST
});

export const updatePasswordSuccess = (
  json: PasswordJson
): ReceivePasswordReturn => ({
  type: UPDATE_PASSWORD_SUCCESS,
  payload: json,
  meta: {
    receivedAt: Date.now()
  }
});

export const updatePasswordError = (
  error: ErrorsI
): GetPasswordErrorReturn => ({
  type: UPDATE_PASSWORD_ERROR,
  error,
  meta: {
    receivedAt: Date.now()
  }
});

export const updatePassword = ({
  password,
  id
}: UpdateParams): CallApiReturnI => ({
  type: CALL_API,
  payload: {
    method: 'POST',
    endpoint: '/api/mesh/',
    options: {
      query: UPDATE_PASSWORD_MUTATION,
      variables: {
        id: id,
        password: password
      }
    },
    actions: {
      request: updatePasswordRequest,
      success: updatePasswordSuccess,
      failure: updatePasswordError
    }
  }
});

export type SetBlurredI = {
  payload: {
    field: string,
    hasBlurred: boolean
  }
} & ActionI;

export const setBlurred = (
  field: string,
  hasBlurred: boolean
): SetBlurredI => ({
  type: SET_BLURRED,
  payload: {
    field,
    hasBlurred
  }
});

export type SetValueI = {
  payload: {
    field: string,
    value: string
  }
} & ActionI;

export const setValue = (field: string, value: string): SetValueI => ({
  type: SET_VALUE,
  payload: {
    field,
    value
  }
});
