// @flow
import { APP_NAMESPACE } from 'shared/constants/application';

const namespace: string = `${APP_NAMESPACE}/user`;

// Actions
export const SET_IS_AUTHED: string = `${namespace}/SET/IS_AUTHED`;

export const FETCH_REQUEST: string = `${namespace}/FETCH/REQUEST`;
export const FETCH_SUCCESS: string = `${namespace}/FETCH/SUCCESS`;
export const FETCH_ERROR: string = `${namespace}/FETCH/ERROR`;

export const CREATE_REQUEST: string = `${namespace}/CREATE/REQUEST`;
export const CREATE_SUCCESS: string = `${namespace}/CREATE/SUCCESS`;
export const CREATE_ERROR: string = `${namespace}/CREATE/ERROR`;

export const UPDATE_PASSWORD_REQUEST: string = `${namespace}/PASSWORD/UPDATE/REQUEST`;
export const UPDATE_PASSWORD_SUCCESS: string = `${namespace}/PASSWORD/UPDATE/SUCCESS`;
export const UPDATE_PASSWORD_ERROR: string = `${namespace}/PASSWORD/UPDATE/ERROR`;

export const SET_BLURRED: string = `${namespace}/SET_BLURRED`;
export const SET_VALUE: string = `${namespace}/SET_VALUE`;
