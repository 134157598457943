// @flow
import type { EventI as APIEventI } from 'redux/interfaces/api/EventI';
import type { EntityRecordI } from './EntityRecord';
import EntityRecord from './EntityRecord';
import { entityTypes } from './types';
import { areDatesAreOnSameDay } from 'shared/utils/dates';

const { REACT_APP_TRAINING_APP_URL: trainingUrl = '' } = process.env;

export const parseEventJsonToEntity = (eventJson: APIEventI): EntityRecordI => {
  const {
    data: {
      id,
      attributes: {
        instructorId,
        occurrenceId,
        name,
        price,
        capacity,
        numberOfOpenSeats,
        isPast,
        canPurchase,
        canRegister,
        location: { name: venue, address1, address2, city, state, postalCode },
        startTime,
        endTime
      }
    }
  } = eventJson;
  const isSingleDay = areDatesAreOnSameDay(
    new Date(startTime),
    new Date(endTime)
  );

  const originatingSiteUrl = `${trainingUrl}/events/${name.toLowerCase()}/${id}`;

  const record = new EntityRecord({
    type: entityTypes.EVENT,
    id,
    occurrenceId,
    instructorId,
    name,
    price,
    capacity,
    numberOfOpenSeats,
    isPast,
    canPurchase,
    canRegister,
    venue,
    address1,
    address2,
    city,
    state,
    postalCode,
    dates: [{ isSingleDay, startTime, endTime }],
    originatingSiteUrl
  });

  // $FlowFixMe, prop check is unnecessary, as immutable record always returns all props
  return record;
};
