// @flow
//import { put, select } from 'redux-saga/effects';
import { select } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
//import { push } from 'connected-react-router';

import { NOT_FOUND_PATH } from 'shared/constants/paths';
import type { ErrorI } from 'redux/interfaces/api/ErrorI';
import { pathnameSelector } from './routerSelectors';

export function* redirectOnError(errors: Array<ErrorI>): Saga<void> {
  const pathname = yield select(pathnameSelector);
  if (pathname !== NOT_FOUND_PATH) {
    let i;
    for (i = 0; i < errors.length; i++) {
      const { status } = errors[i];
      if (status === 404) {
        break;
      }
    }
  }
}
