import * as jscookie from 'js-cookie';

export const getDecodedAccessToken = () => {
  const accessToken = jscookie.get('uscca_access');
  if (!accessToken) {
    return null;
  } else {
    try {
      const encodedValue = accessToken.split('.')[1];
      const decodedValue = JSON.parse(window.atob(encodedValue));
      if (Math.ceil(Date.now() / 1000) >= decodedValue.exp) {
        return null;
      } else {
        return decodedValue;
      }
    } catch {
      return null;
    }
  }
};

export const getAccessToken = () => {
  const accessToken = jscookie.get('uscca_access');
  return accessToken || '';
};

export const isAccessTokenExpired = (): boolean => {
  const token = getDecodedAccessToken();

  if (token) {
    return Math.round(Date.now() / 1000) >= (token.exp || 0);
  }

  return true;
};
