// @flow
import { retailPartnerOrgs } from 'shared/constants/retailPartnersOrgs';

type Org = {
  locationId?: string,
  orgKey?: string,
  url?: string,
  parentOrganizationId?: string,
  isMainOrg?: boolean,
  orgDisplayName?: string,
  orgShortDisplayName?: string,
  customConfirmationMessage?: ?string
};

const orgs = [
  {
    orgKey: 'ruralKing',
    orgDisplayName: 'Rural King',
    orgShortDisplayName: 'Rural King',
    url: 'https://ruralking.usconcealedcarry.com',
    parentOrganizationId: '4d900ce4-d068-11ec-a78d-02420a0002af',
    customConfirmationMessage:
      'When you arrive at **Rural King** go to the customer service desk to check into your class.'
  },
  {
    orgKey: 'abrAcademy',
    orgDisplayName: '5.11 ABR Academy',
    orgShortDisplayName: '5.11',
    url: 'https://511abracademy.usconcealedcarry.com',
    parentOrganizationId: '66c9dfb2-11ce-11ed-896f-0242ac12000e',
    customConfirmationMessage: null
  }
];

const getOrg = (id: string): Org | null => {
  const partnerOrg = retailPartnerOrgs.find((org) => org.locationId === id);
  // If not a partnerOrg, stop!
  if (!partnerOrg) {
    return null;
  }
  const { retailPartnerKey } = partnerOrg;
  // Get org props
  const orgProps = orgs.find((org) => org.orgKey === retailPartnerKey);

  const org = {
    ...orgProps,
    locationId: id,
    isMainOrg: id === orgProps?.parentOrganizationId
  };
  return org;
};

export default getOrg;
