/* eslint no-console: 0 */
// @flow
const { LOAD_SEGMENT, SEGMENT_TRACKING } = process.env;

export default function enterEmailJourney(): boolean {
  console.log('🫥 enterEmailJourney function called');
  switch (true) {
    case LOAD_SEGMENT === 'false':
      console.log('🌚 LOAD_SEGMENT set to false');
      return false;
    case SEGMENT_TRACKING === 'false':
      console.log('🌚 SEGMENT_TRACKING set to false');
      return false;
    // $FlowFixMe
    case typeof analytics !== 'object':
      console.log('🌚 analytics not loaded, cannot send action');
      return false;
    default:
      console.log('🫥 emailJourney entered');
      analytics.track('enterJourney', {
        canvasName: 'Training_Leadsource'
      });
      return true;
  }
}
