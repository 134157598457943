// @flow

export const APP_NAMESPACE: string = 'deltaCheckout';

// Google Tag Manager
export const GTM_ID: string = 'GTM-MNXQGG';

// Stripe
export const TEST_CLIENT_ID: string = 'ca_EIehlaIVzsz15PMzG1EeDuMdSdwQcIdy';
export const CLIENT_ID: string = 'ca_EIehaW5zuS3tNaQ3nOfkW11sgOI0M30Z';

// Terms, Conditions, Legal
export const SITE_TERMS_AND_CONDITIONS_URL: string =
  'https://www.notion.so/usccatech/USCCA-Training-Website-Terms-of-Service-6f5781f3c75c49c6a50a032c7041d888';
