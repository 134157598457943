// @flow
type valuesType = {
  password?: string,
  passwordConfirmation?: string
};

type errorsType = {
  password?: string,
  passwordConfirmation?: string
};

const validate = ({
  password,
  passwordConfirmation
}: valuesType): errorsType => {
  const errors = {};
  if (!password) {
    errors.password = 'Required';
  }

  if (!passwordConfirmation) {
    errors.passwordConfirmation = 'Required';
  }

  if (password && password.length < 8) {
    errors.password = 'Password must be at least eight characters long';
  }

  if (passwordConfirmation && passwordConfirmation.length < 8) {
    errors.passwordConfirmation =
      'Password must be at least eight characters long';
  }

  if (password !== passwordConfirmation) {
    errors.passwordConfirmation = 'Passwords do not match!';
  }

  return errors;
};

export default validate;
