// @flow
import { put, select, take } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import cookie from 'cookie-cutter';
import {
  PAYMENT_INFO_STEP,
  CONFIRM_ORDER_STEP,
  setStep
} from 'redux/modules/checkout/checkout';
import { entityDataSelector } from 'redux/modules/entity/entitySelectors';
import type { EntityRecordI } from 'redux/modules/entity/EntityRecord';
import { CREATE_SUCCESS } from './userActions';

const { REACT_APP_BASE_COOKIE_DOMAIN = '' } = process.env;

export function* addCookiesOnCreateUserSuccess(): Saga<void> {
  for (;;) {
    const action = yield take(CREATE_SUCCESS);
    const { token, ttl } = action.payload.data.createUserIdentity.userToken;

    const opts = {
      Domain: REACT_APP_BASE_COOKIE_DOMAIN,
      expiration: ttl
    };
    cookie.set('uscca_access', token, opts);
    cookie.set('SESSusccaaccess', token, opts);

    const entityData: EntityRecordI = yield select(entityDataSelector);
    const step = !!entityData.price ? PAYMENT_INFO_STEP : CONFIRM_ORDER_STEP;
    yield put(setStep(step));
  }
}
