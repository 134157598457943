// @flow
import fetch from 'isomorphic-fetch';
import { call, fork, put, take } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import * as Sentry from '@sentry/browser';

import {
  getAccessToken,
  getDecodedAccessToken,
  isAccessTokenExpired
} from 'shared/utils/authCookie';
import { redirectOnError } from './redirects';

const { REACT_APP_SENTRY_ERROR_TRACKING } = process.env;
const includeSentry = REACT_APP_SENTRY_ERROR_TRACKING === 'true';

export const CALL_API = Symbol('Call API');

export type CallApiReturnI = {
  type: symbol,
  payload: {
    method: string,
    options?: {
      [string]: any
    },
    endpoint: string,
    params?: Array<string>,
    actions: {
      request: (any) => any,
      success: (any) => any,
      failure: (any) => any
    },
    isRedirectOnError?: boolean,
    headers?: Object
  }
};

type HeaderI = {
  Accept: string,
  'Content-Type': string,
  'X-Auth-Token'?: string
};

export type CallApiReturnType = CallApiReturnI;

export const makeApiCall = (
  method: string,
  endpoint: string,
  options: {
    [string]: mixed
  },
  headers?: Object
) => {
  const body = options ? JSON.stringify(options) : null;
  const accessToken = getAccessToken();
  const decodedAccessToken = getDecodedAccessToken();
  const isExpired = isAccessTokenExpired();

  let headerObj: HeaderI = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };

  if (!isExpired && !!decodedAccessToken?.userId) {
    headerObj['X-Auth-Token'] = accessToken;
  }

  if (headers) {
    Object.keys(headers).forEach((k) => {
      headerObj[k] = headers[k];
    });
  }

  let params: Object = {
    method,
    mode: 'cors',
    headers: new Headers(headerObj)
  };

  if (body) {
    params.body = body;
  }

  return fetch(endpoint, params).then((response) => {
    if (!response.ok && typeof response.body !== 'object') {
      throw Error(response.statusText);
    }
    return method === 'DELETE' ? {} : response.json();
  });
};

export function* fetchIt(action: CallApiReturnI): Saga<void> {
  const {
    actions: { request, success, failure },
    endpoint,
    method,
    options,
    params = [],
    isRedirectOnError,
    headers
  } = action.payload;

  const requestAction = request(...params);

  yield put(requestAction);

  try {
    const json = yield call(makeApiCall, method, endpoint, options, headers);

    if (json.hasOwnProperty('errors')) {
      const failureAction = failure(json.errors);
      yield put(failureAction);
      if (isRedirectOnError) {
        yield fork(redirectOnError, json.errors);
      }
    } else {
      const successAction = success(json);
      yield put(successAction);
    }
  } catch (error) {
    // Send state to Sentry
    if (includeSentry) {
      // Send caught error to Sentry
      Sentry.captureException(error);
    }

    // make error consistent with what api returns
    let errors = [
      {
        status: '',
        source: '',
        title: '',
        detail: error.toString()
      }
    ];
    const failureAction = failure(errors);
    yield put(failureAction);
  }
}

export function* callApi(): Saga<void> {
  for (;;) {
    const action = yield take(CALL_API);
    yield fork(fetchIt, action);
  }
}

export default callApi;
