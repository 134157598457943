// @flow
import React, { useEffect } from 'react';
import type { Node } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import Loadable from 'react-loadable';
import { Spinner } from '@blueprintjs/core';
import * as jscookie from 'js-cookie';

import { NOT_FOUND_PATH, REGISTER_PATH } from 'shared/constants/paths';
import { loadSegment } from './loadSegment';
import DefaultLayout from 'shared/layout/DefaultLayout';

const Checkout = Loadable({
  loader: () => import('./containers/Checkout/Checkout'),
  loading: Spinner
});

const NotFound = Loadable({
  loader: () => import('./containers/NotFound/NotFound'),
  loading: Spinner
});

type Props = {
  history: any
};

const Routes = ({ history }: Props): Node => {
  // Datalayer + Segment
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    import(
      '../node_modules/@delta-defense/universal-components/common/scripts/datalayer-universal'
    );
    loadSegment();

    // Identify user
    if (window.analytics) {
      const accessToken = jscookie.get('uscca_access');
      if (accessToken) {
        try {
          const encodedValue = accessToken.split('.')[1];
          const decodedValue = JSON.parse(window.atob(encodedValue));
          window.analytics.identify(decodedValue.userId);
        } catch {
          // do nothing
        }
      }
    }
  }, []);

  // If Segment loaded & path changed, call analytics.page()
  useEffect(() => {
    if (window.analytics) {
      window.analytics.page();
    }
  }, [history.location.pathname]);

  return (
    <ConnectedRouter history={history}>
      <Route>
        <DefaultLayout>
          <Switch>
            <Route exact path={NOT_FOUND_PATH} component={NotFound} />
            <Route
              exact
              path={`/${REGISTER_PATH}/:slug/:id`}
              component={Checkout}
            />
            <Redirect from="*" to="/404" />
          </Switch>
        </DefaultLayout>
      </Route>
    </ConnectedRouter>
  );
};

export default Routes;
