// @flow
import { handleActions } from 'redux-actions';

import { APP_NAMESPACE } from 'shared/constants/application';
import { CALL_API } from 'redux/middleware/sagas/api';
import type { CallApiReturnType } from 'redux/middleware/sagas/api';
import { NONE, PENDING, SUCCESS, ERROR } from 'shared/constants/status';
import type { ActionI } from 'redux/interfaces/ActionI';
import type { ErrorI } from 'redux/interfaces/api/ErrorI';
import { parseErrors } from 'redux/parsers/parseErrors';
import type { ChargeI } from 'redux/interfaces/api/ChargeI';
import type { TokenI } from './TokenI';
import { getTrackingFields } from 'shared/constants/trackingFields';

const namespace: string = `${APP_NAMESPACE}/payment`;

// Actions
export const SET_SOURCE: string = `${namespace}/SOURCE/SET`;
export const BUY_CLASS_REQUEST: string = `${namespace}/CLASS/BUY/REQUEST`;
export const BUY_CLASS_SUCCESS: string = `${namespace}/CLASS/BUY/SUCCESS`;
export const BUY_CLASS_ERROR: string = `${namespace}/CLASS/BUY/ERROR`;
export const CLEAR: string = `${namespace}/CLEAR`;

// Action Creators
type SetSourceI = {
  payload: {
    paymentSource: string,
    brand: string,
    ccLast4: string,
    postalCode: string
  }
} & ActionI;

export const setSource = ({
  id: paymentSource,
  card: { address_zip: postalCode, brand, last4: ccLast4 }
}: TokenI): SetSourceI => ({
  type: SET_SOURCE,
  payload: {
    paymentSource,
    brand,
    ccLast4,
    postalCode
  }
});

export const buyClassRequest = (): ActionI => ({
  type: BUY_CLASS_REQUEST
});

type BuyClassSuccessI = {
  payload: {
    chargeId: string,
    chargeAmount: number
  },
  meta: {
    receivedAt: number
  }
} & ActionI;

export const buyClassSuccess = ({
  data: {
    attributes: { chargeId, amount: chargeAmount }
  }
}: ChargeI): BuyClassSuccessI => ({
  type: BUY_CLASS_SUCCESS,
  payload: {
    chargeId,
    chargeAmount
  },
  meta: {
    receivedAt: Date.now()
  }
});

type BuyClassErrorI = {
  payload: Array<string>,
  error: boolean
} & ActionI;

export const buyClassError = (errors: Array<ErrorI>): BuyClassErrorI => ({
  type: BUY_CLASS_ERROR,
  payload: parseErrors(errors),
  error: true
});

export const clear = (): ActionI => ({
  type: CLEAR
});

// Async Actions
export type BuyClassOptionsI = {
  occurrenceId: string,
  firstName: string,
  lastName: string,
  phone: string,
  email: string,
  postalCode: string,
  paymentSource: string,
  isPromo: boolean,
  isEvent: boolean,
  isRetailPartner: boolean
};

export const buyClass = (
  accessToken: string,
  {
    isPromo = false,
    isEvent = false,
    isRetailPartner = false,
    ...options
  }: BuyClassOptionsI
): CallApiReturnType => ({
  type: CALL_API,
  payload: {
    method: 'POST',
    headers: {
      'X-Auth-Token': accessToken
    },
    endpoint: '/api/training/charges',
    options: {
      ...options,
      ...getTrackingFields({ isPromo, isEvent, isRetailPartner })
    },
    actions: {
      request: buyClassRequest,
      success: buyClassSuccess,
      failure: buyClassError
    }
  }
});

// Initial State
export type StateI = {
  paymentSource: string,
  brand: string,
  ccLast4: string,
  postalCode: string,
  status: string,
  chargeId: string,
  chargeAmount: number,
  receivedAt: number,
  errors: Array<string>
};

const initialState: StateI = {
  paymentSource: '',
  brand: '',
  ccLast4: '',
  postalCode: '',
  status: NONE,
  chargeId: '',
  chargeAmount: 0,
  receivedAt: 0,
  errors: []
};

// Reducer
const checkout = handleActions(
  {
    [SET_SOURCE]: (
      state: StateI,
      { payload: { paymentSource, brand, ccLast4, postalCode } }: SetSourceI
    ): StateI => ({
      ...state,
      paymentSource,
      brand,
      ccLast4,
      postalCode
    }),
    [BUY_CLASS_REQUEST]: (state: StateI): StateI => ({
      ...state,
      status: PENDING,
      chargeId: '',
      chargeAmount: 0,
      receivedAt: 0,
      errors: []
    }),
    [BUY_CLASS_SUCCESS]: (
      state: StateI,
      {
        payload: { chargeId, chargeAmount },
        meta: { receivedAt }
      }: BuyClassSuccessI
    ): StateI => ({
      ...state,
      status: SUCCESS,
      chargeId,
      chargeAmount: chargeAmount / 100,
      receivedAt
    }),
    [BUY_CLASS_ERROR]: (
      state: StateI,
      { payload: errors }: BuyClassErrorI
    ): StateI => ({
      ...state,
      status: ERROR,
      errors
    }),
    [CLEAR]: (state: StateI): StateI => initialState
  },
  initialState
);

export default checkout;
