// @flow
import * as jscookie from 'js-cookie';

type TrackingFields = {|
  tID: string | void,
  tracking: {
    origin: string,
    medium: string,
    campaign: string,
    audience: string,
    targeting: string,
    pageType: string,
    type: string
  }
|};

const {
  REACT_APP_RETAIL_PARTNER_TID: retailPartnerTID = '',
  REACT_APP_DEFAULT_TID: defaultTID = '',
  REACT_APP_EVENTS_TID: eventsTID = '',
  REACT_APP_PROMO_TID: promoTID = ''
} = process.env;

const defaultTracking: TrackingFields = {
  tID: defaultTID,
  tracking: {
    origin: 'Training',
    medium: 'Platform',
    campaign: 'Web Purchase',
    audience: '',
    targeting: '',
    pageType: 'Direct Sales',
    type: 'Class Purchase'
  }
};

const retailPartnerTracking = {
  ...defaultTracking,
  tID: retailPartnerTID
};

const eventTracking: TrackingFields = {
  tID: eventsTID,
  tracking: {
    origin: 'Training',
    medium: 'Platform',
    campaign: 'Web Purchase',
    audience: '',
    targeting: '',
    pageType: 'Direct Sales',
    type: 'Event Purchase'
  }
};

const promoTracking: TrackingFields = {
  tID: promoTID,
  tracking: {
    origin: 'Promotion',
    medium: 'Platform',
    campaign: 'Web Purchase',
    audience: '-',
    targeting: '-',
    pageType: 'Direct Sales',
    type: 'Training Promo'
  }
};

type GetTrackingFieldsParams = {
  isPromo: boolean,
  isEvent: boolean,
  isRetailPartner: boolean
};

// pass isMicrositeRetailer
export const getTrackingFields = ({
  isPromo = false,
  isEvent = false,
  isRetailPartner = false
}: GetTrackingFieldsParams): TrackingFields => {
  let defaults;
  const cookieLeadFields = jscookie.get('uscca_lead_fields');
  const decodedLeadFields = !!cookieLeadFields ? atob(cookieLeadFields) : null;
  switch (true) {
    case isRetailPartner:
      defaults = retailPartnerTracking;
      break;
    case isPromo:
      defaults = promoTracking;
      break;
    case isEvent:
      defaults = eventTracking;
      break;
    default:
      defaults = defaultTracking;
  }
  const jsonLeadFields =
    !!decodedLeadFields && !isEvent ? JSON.parse(decodedLeadFields) : defaults;
  return jsonLeadFields;
};
