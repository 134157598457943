// @flow
import { CALL_API } from 'redux/middleware/sagas/api';
import type { CallApiReturnType } from 'redux/middleware/sagas/api';
import type { ActionI } from 'redux/interfaces/ActionI';
import type { ErrorI } from 'redux/interfaces/api/ErrorI';
import type { OccurrenceI as APIOccurrenceI } from 'redux/interfaces/api/OccurrenceI';
import { FETCH, SET, FAIL } from './occurrenceActions';

// action creators
export type FetchOccurrenceI = {
  payload: {
    id: string
  }
} & ActionI;

export const fetchOccurrence = (id: string): FetchOccurrenceI => ({
  type: FETCH,
  payload: {
    id
  }
});

export type SetOccurrenceI = {
  payload: APIOccurrenceI,
  meta: {
    receivedAt: number
  }
} & ActionI;

export const setOccurrence = (json: APIOccurrenceI): SetOccurrenceI => ({
  type: SET,
  payload: json,
  meta: {
    receivedAt: Date.now()
  }
});

export type FetchOccurrenceFailureI = {
  payload: Array<ErrorI>,
  error: boolean
} & ActionI;

export const fetchOccurrenceFailure = (
  errors: Array<ErrorI>
): FetchOccurrenceFailureI => ({
  type: FAIL,
  payload: errors,
  error: true
});

// Async Actions
export const getOccurrence = (id: string): CallApiReturnType => ({
  type: CALL_API,
  payload: {
    method: 'GET',
    endpoint: `/api/training/occurrences/${id}`,
    params: [id],
    actions: {
      request: fetchOccurrence,
      success: setOccurrence,
      failure: fetchOccurrenceFailure
    }
  }
});
