// @flow
import { handleActions } from 'redux-actions';

import { APP_NAMESPACE } from 'shared/constants/application';
import { CALL_API } from 'redux/middleware/sagas/api';
import type { CallApiReturnType } from 'redux/middleware/sagas/api';
import { NONE, PENDING, SUCCESS, ERROR } from 'shared/constants/status';
import type { ActionI } from 'redux/interfaces/ActionI';
import type { ErrorI } from 'redux/interfaces/api/ErrorI';
import { parseErrors } from 'redux/parsers/parseErrors';
import { getTrackingFields } from 'shared/constants/trackingFields';

const namespace: string = `${APP_NAMESPACE}/register`;

// Actions
export const REGISTER_REQUEST: string = `${namespace}/CLASS/REGISTER/REQUEST`;
export const REGISTER_SUCCESS: string = `${namespace}/CLASS/REGISTER/SUCCESS`;
export const REGISTER_ERROR: string = `${namespace}/CLASS/REGISTER/ERROR`;
export const CLEAR: string = `${namespace}/CLEAR`;

// Action Creators
export const registerRequest = (): ActionI => ({
  type: REGISTER_REQUEST
});

type RegisterSuccessI = {
  meta: {
    receivedAt: number
  }
} & ActionI;

export const registerSuccess = (): RegisterSuccessI => ({
  type: REGISTER_SUCCESS,
  meta: {
    receivedAt: Date.now()
  }
});

type RegisterErrorI = {
  payload: Array<string>,
  error: boolean
} & ActionI;

export const registerError = (errors: Array<ErrorI>): RegisterErrorI => ({
  type: REGISTER_ERROR,
  payload: parseErrors(errors),
  error: true
});

export const clear = (): ActionI => ({
  type: CLEAR
});

// Async Actions
export type RegisterOptionsI = {
  occurrenceId: string,
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  isPromo: boolean,
  isEvent: boolean,
  isRetailPartner: boolean
};

export const registerToClass = (
  accessToken: string,
  {
    isPromo = false,
    isEvent = false,
    isRetailPartner = false,
    ...options
  }: RegisterOptionsI
): CallApiReturnType => ({
  type: CALL_API,
  payload: {
    method: 'POST',
    headers: {
      'X-Auth-Token': accessToken
    },
    endpoint: '/api/training/registrants',
    options: {
      ...options,
      ...getTrackingFields({ isPromo, isEvent, isRetailPartner }),
      isManualRegistrant: false
    },
    actions: {
      request: registerRequest,
      success: registerSuccess,
      failure: registerError
    }
  }
});

// Initial State
export type StateI = {
  status: string,
  receivedAt: number,
  errors: Array<string>
};

const initialState: StateI = {
  status: NONE,
  receivedAt: 0,
  errors: []
};

// Reducer
const register = handleActions(
  {
    [REGISTER_REQUEST]: (state: StateI): StateI => ({
      ...state,
      status: PENDING,
      receivedAt: 0,
      errors: []
    }),
    [REGISTER_SUCCESS]: (
      state: StateI,
      { meta: { receivedAt } }: RegisterSuccessI
    ): StateI => ({
      ...state,
      status: SUCCESS,
      receivedAt
    }),
    [REGISTER_ERROR]: (
      state: StateI,
      { payload: errors }: RegisterErrorI
    ): StateI => ({
      ...state,
      status: ERROR,
      errors
    }),
    [CLEAR]: (state: StateI): StateI => initialState
  },
  initialState
);

export default register;
