// @flow
import type { RouterStateI } from 'redux/interfaces/RouterStateI';

export const pathnameSelector = ({
  router: {
    location: { pathname }
  }
}: {
  router: RouterStateI
}): string => pathname;

export const searchSelector = ({
  router: {
    location: { search }
  }
}: {
  router: RouterStateI
}): ?string => search;

export const querySelector = ({
  router: {
    location: { query }
  }
}: {
  router: RouterStateI
}): ?{ [string]: string } => query;
