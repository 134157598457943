// @flow
import type { CourseI as APICourseI } from 'redux/interfaces/api/CourseI';
import type { EntityRecordI } from './EntityRecord';
import EntityRecord from './EntityRecord';
import { entityTypes } from './types';
import getOrg from 'shared/utils/getOrg';

const { REACT_APP_TRAINING_APP_URL: trainingUrl = '' } = process.env;

export const parseCourseJsonToEntity = (
  courseJson: APICourseI,
  originalRecord?: EntityRecordI
): EntityRecordI => {
  const existingValues = originalRecord ? originalRecord.toJS() : {};
  const {
    data: {
      id,
      attributes: {
        instructorId,
        name,
        price,
        capacity,
        canPurchase,
        canRegister,
        organizationId,
        location: { name: venue, address1, address2, city, state, postalCode },
        registrationQuestions,
        willShipTangibleGoods,
        willAssignPrerequisiteELearning
      }
    },
    included
  } = courseJson;
  // Curriculum Data
  const curriculumCode =
    included &&
    included.length &&
    included[0].attributes.hasOwnProperty('abbreviation')
      ? included[0].attributes.abbreviation
      : '';

  const hasTangibleGoods =
    included &&
    included.length &&
    included[0].attributes.hasOwnProperty('hasTangibleGoods')
      ? included[0].attributes.hasTangibleGoods
      : false;

  const isCiLevel = curriculumCode.slice(-2) === 'CI';
  const isPromo = curriculumCode === 'ULS';
  const org = getOrg(organizationId);
  const isRetailPartner = !!org;
  const url = org?.url || '';
  const orgDisplayName = org?.orgDisplayName || '';
  const orgShortDisplayName = org?.orgShortDisplayName || '';
  const customConfirmationMessage = org?.customConfirmationMessage || '';
  const originatingSiteUrl = isRetailPartner
    ? `${url}/classes/${name.toLowerCase()}/${id}`
    : `${trainingUrl}/class/${name.toLowerCase()}/${id}`;

  const record = new EntityRecord({
    ...existingValues,
    type: entityTypes.CLASS,
    id,
    instructorId,
    name,
    price,
    capacity,
    canPurchase,
    canRegister,
    isRetailPartner,
    organizationId,
    venue,
    address1,
    address2,
    city,
    state,
    postalCode,
    curriculumCode,
    isCiLevel,
    hasTangibleGoods,
    isPromo,
    registrationQuestions,
    willShipTangibleGoods,
    willAssignPrerequisiteELearning,
    originatingSiteUrl,
    orgDisplayName,
    orgShortDisplayName,
    customConfirmationMessage
  });

  // $FlowFixMe, prop check is unnecessary, as immutable record always returns all props
  return record;
};
