import type { ErrorI } from 'shared/interfaces/api/ErrorI';

type APIErrorsI = Array<ErrorI>;

export const parseErrors = (apiErrors: APIErrorsI) => {
  let errors = [];

  apiErrors.forEach(function ({ detail }: ErrorI) {
    errors.push(detail);
  });

  return errors;
};
