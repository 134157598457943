// @flow
type Org = {
  locationId: string,
  retailPartnerKey: string
};

export const ruralKingOrgs: Array<Org> = [
  {
    locationId: '4d900ce4-d068-11ec-a78d-02420a0002af',
    retailPartnerKey: 'ruralKing'
  },
  {
    locationId: 'd6f33b78-d068-11ec-8a11-02420a0001e3',
    retailPartnerKey: 'ruralKing'
  },
  {
    locationId: '37ce973a-d069-11ec-b743-02420a0001e3',
    retailPartnerKey: 'ruralKing'
  },
  {
    locationId: '766267f6-d069-11ec-9dbc-02420a0002af',
    retailPartnerKey: 'ruralKing'
  },
  {
    locationId: '9d654ff8-d069-11ec-8346-02420a0002af',
    retailPartnerKey: 'ruralKing'
  },
  {
    locationId: 'da84634c-d069-11ec-bab1-02420a0001e3',
    retailPartnerKey: 'ruralKing'
  },
  {
    locationId: '005439c6-d06a-11ec-b26d-02420a0002af',
    retailPartnerKey: 'ruralKing'
  },
  {
    locationId: 'd430ca52-d06a-11ec-8f44-02420a0001e3',
    retailPartnerKey: 'ruralKing'
  },
  {
    locationId: '0f0f90a4-d06b-11ec-8dd2-02420a0002ad',
    retailPartnerKey: 'ruralKing'
  },
  {
    locationId: '32994fd8-d06b-11ec-af2f-02420a0002af',
    retailPartnerKey: 'ruralKing'
  },
  {
    locationId: '38ca16b0-ec1a-11ec-9b00-02420a0002c3',
    retailPartnerKey: 'ruralKing'
  },
  {
    locationId: 'fc7cf842-ec1b-11ec-b71e-02420a0002c3',
    retailPartnerKey: 'ruralKing'
  },
  {
    locationId: '5ab8e2b8-d06a-11ec-82fc-02420a0002af',
    retailPartnerKey: 'ruralKing'
  }
];

export const abrAcademyOrgs: Array<Org> = [
  {
    locationId: '66c9dfb2-11ce-11ed-896f-0242ac12000e',
    retailPartnerKey: 'abrAcademy'
  },
  {
    locationId: '8565bfaa-11aa-11ed-a015-0242ac12000e',
    retailPartnerKey: 'abrAcademy'
  },
  {
    locationId: '37c2c00e-11aa-11ed-81d5-02420a0002c3',
    retailPartnerKey: 'abrAcademy'
  },
  {
    locationId: '9243516c-1419-11ed-93c5-0242ac12000e',
    retailPartnerKey: 'abrAcademy'
  },
  {
    locationId: 'f75d44bc-11a9-11ed-9cc9-0242ac12000e',
    retailPartnerKey: 'abrAcademy'
  },
  {
    locationId: 'c7185a8e-11aa-11ed-ba5d-02420a0002c3',
    retailPartnerKey: 'abrAcademy'
  },
  {
    locationId: '622b073e-11aa-11ed-ad53-0242ac12000e',
    retailPartnerKey: 'abrAcademy'
  },
  {
    locationId: '09a0a3b0-1e40-11ed-9b44-02420a0002c3',
    retailPartnerKey: 'abrAcademy'
  },
  {
    locationId: '794758b2-1e40-11ed-bef6-0242ac12000e',
    retailPartnerKey: 'abrAcademy'
  },
  {
    locationId: '225b61ec-35f6-11ed-998b-0242ac120005',
    retailPartnerKey: 'abrAcademy'
  },
  {
    locationId: 'c73afa46-1e41-11ed-ab10-02420a0002c3',
    retailPartnerKey: 'abrAcademy'
  },
  {
    locationId: '2bdff13c-1e41-11ed-a30a-0242ac12000e',
    retailPartnerKey: 'abrAcademy'
  },
  {
    locationId: '3e76795c-1e40-11ed-99a4-0242ac12000e',
    retailPartnerKey: 'abrAcademy'
  }
];

export const retailPartnerOrgs = [...ruralKingOrgs, ...abrAcademyOrgs];
