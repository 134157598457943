import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { FocusStyleManager } from '@blueprintjs/core';

import initializeSentry from './initializeSentry';
import Routes from './routes.js';
import * as serviceWorker from './serviceWorker';
import configureStore from './redux/configureStore';
import rootSaga from './redux/middleware/sagas/rootSaga';

import './styles/main.css';
import '@blueprintjs/core/lib/css/blueprint.css';

const { REACT_APP_SENTRY_ERROR_TRACKING } = process.env;
const includeSentry = REACT_APP_SENTRY_ERROR_TRACKING === 'true';

if (includeSentry) {
  initializeSentry();
}

const history = createBrowserHistory();
const store = configureStore(history);

FocusStyleManager.onlyShowFocusOnTabs();
store.runSaga(rootSaga);

ReactDOM.render(
  <Provider store={store}>
    <Routes history={history} />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
