// @flow
import validator from 'validator';

type valuesType = {
  curriculumCode?: string,
  email: string,
  firstName: string,
  hasTangibleGoods?: boolean,
  lastName: string,
  phone: string,
  registrationQuestions?: Array<mixed>,
  registrationAnswers?: Array<mixed>,
  shippingAddress1?: string,
  shippingAddress2?: string,
  shippingCity?: string,
  shippingPostalCode?: string,
  shippingState?: string,
  willShipTangibleGoods?: boolean,
  isRetailPartner: boolean,
  confirmAdult?: boolean,
  acceptConsentDisclaimer?: boolean
};

type errorsType = {
  curriculumCode?: string,
  email?: string,
  firstName?: string,
  hasTangibleGoods?: boolean,
  lastName?: string,
  phone?: string,
  registrationQuestions?: string,
  registrationAnswers?: string,
  shippingAddress1?: string,
  shippingAddress2?: string,
  shippingCity?: string,
  shippingPostalCode?: string,
  shippingState?: string,
  willShipTangibleGoods?: string,
  confirmAdult?: string,
  acceptConsentDisclaimer?: string
};

const validate = ({
  curriculumCode,
  email,
  firstName,
  hasTangibleGoods,
  lastName,
  phone,
  registrationQuestions,
  registrationAnswers,
  shippingAddress1,
  shippingAddress2,
  shippingCity,
  shippingPostalCode,
  shippingState,
  willShipTangibleGoods,
  isRetailPartner,
  confirmAdult,
  acceptConsentDisclaimer
}: valuesType): errorsType => {
  const errors = {};
  const validPostalCode = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

  if (!firstName) {
    errors.firstName = 'Required';
  }

  if (!lastName) {
    errors.lastName = 'Required';
  }

  if (!email) {
    errors.email = 'Required';
  }

  if (email && !validator.isEmail(email)) {
    errors.email = 'Invalid email address';
  }

  if (
    !!phone &&
    (phone.length !== 12 ||
      phone.split('-').length - 1 !== 2 ||
      phone.replace(/[^0-9]/g, '').length !== 10)
  ) {
    errors.phone = `Format should be '555-555-5555'`;
  }

  if (registrationQuestions && registrationQuestions.length > 0) {
    if (registrationAnswers && registrationAnswers.length === 0) {
      errors.registrationAnswers = 'Required';
    }
  }

  if (willShipTangibleGoods) {
    if (!shippingAddress1) {
      errors.shippingAddress1 = 'Required';
    }
    // Check for PO Box address
    // normalize string first; lowercase and remove periods
    if (
      shippingAddress1 &&
      shippingAddress1.toLowerCase().replace(/\./g, '').includes('po box')
    ) {
      errors.shippingAddress1 = 'No PO boxes allowed';
    }
    if (
      shippingAddress2 &&
      shippingAddress2.toLowerCase().replace(/\./g, '').includes('po box')
    ) {
      errors.shippingAddress1 = 'No PO boxes allowed';
    }

    if (!shippingCity) {
      errors.shippingCity = 'Required';
    }

    if (!shippingState) {
      errors.shippingState = 'Required';
    }

    if (!shippingPostalCode) {
      errors.shippingPostalCode = 'Required';
    }
    if (shippingPostalCode && !validPostalCode.test(shippingPostalCode)) {
      errors.shippingPostalCode = 'Enter a valid zip code';
    }
  }

  // Retail Partners
  if (isRetailPartner && !confirmAdult) {
    errors.confirmAdult = 'Required';
  }

  if (isRetailPartner && !acceptConsentDisclaimer) {
    errors.acceptConsentDisclaimer = 'Required';
  }

  return errors;
};

export default validate;
