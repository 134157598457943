// @flow
import React from 'react';
import type { Node } from 'react';

type Props = {
  className: ?string
};

let LogoIcon = ({ className }: Props): Node => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xlinkHref="http://www.w3.org/1999/xlink"
    x="0"
    y="0"
    viewBox="0 0 414.6 432.5"
    aria-labelledby="title"
  >
    <title>USCCA Training</title>
    <path d="M289.9,394.1c4.3,0,7.7-3.4,7.7-7.7c0-4.3-3.4-7.7-7.7-7.7c-4.1,0-7.7,3.4-7.7,7.7 C282.2,390.7,285.6,394.1,289.9,394.1z M289.9,379.9c3.6,0,6.4,2.8,6.4,6.4c0,3.6-2.8,6.4-6.4,6.4c-3.6,0-6.4-2.8-6.4-6.4 C283.5,382.8,286.3,379.9,289.9,379.9z" />
    <path d="M288,387.3h1.3l2.3,3.4h1.7l-2.3-3.6c0.6,0,2.3-0.6,2.3-2.6c0-1.7-1.5-2.6-3-2.6h-3.6v8.7h1.3L288,387.3 L288,387.3z M288,383.1h2.3c0.8,0,1.5,0.6,1.5,1.5c0,0.9-0.8,1.5-1.5,1.5H288V383.1z" />
    <path d="M302.3,288.4h36.7C392,186.1,382.6,91.9,382.6,91.9C297.1,71,232.8,21.1,211.2,0v42.8 c17.9,14.3,67.4,52.4,140.1,74.4C351.5,117.2,353.4,199.7,302.3,288.4z" />
    <path d="M379,304.2c-1.3-2.3-2.8-4.1-4.5-5.8c-1.7-1.5-4-2.3-6.8-2.3s-5.1,0.8-7,2.4c-1.9,1.7-3.4,3.6-4.5,5.7 L317,369.6h23.4l26.9-48l7.2,13.4h-7.9l-11.1,19h29.2c3.6,6.8,8.3,15.4,8.3,15.4h21.7L379,304.2z" />
    <path d="M284.2,288.4c50.9-87.8,52.6-163.3,52.6-163.3c-21.5,101.2-79.9,163.1-80.1,163.3H284.2z" />
    <path d="M278.8,350.9c-2.4,0-4.5-0.4-6.6-1.3c-2.1-0.8-3.8-2.1-5.3-3.4c-1.5-1.5-2.6-3.4-3.6-5.5 c-0.8-2.1-1.3-4.5-1.3-7c0-2.6,0.4-4.9,1.3-7c0.9-2.1,2.1-4,3.6-5.5c1.5-1.5,3.2-2.6,5.3-3.6c2.1-0.9,4.3-1.3,6.6-1.3h28.6 l11.3-18.6h-39.9c-4.9,0-9.6,0.8-13.8,2.4s-7.9,4.1-10.9,7.2c-3,3.2-5.5,6.8-7.2,11.1c-1.7,4.3-2.6,9-2.6,14.3s0.9,10.2,2.6,14.5 c1.7,4.5,4.1,8.3,7.2,11.7c3,3.2,6.8,5.8,10.9,7.7c4.1,1.9,8.9,2.8,13.8,2.8h28.6l11.9-18.6L278.8,350.9L278.8,350.9z" />
    <path d="M211.2,394.3v38.2c24.7-17,45.4-35.2,63.7-53.9h-45.4C223.6,383.9,217.6,389.2,211.2,394.3z" />
    <path d="M237.9,350.9h-40.5c-2.4,0-4.5-0.4-6.6-1.1s-3.8-2.1-5.3-3.6c-1.5-1.5-2.6-3.4-3.6-5.5 c-0.8-2.1-1.3-4.5-1.3-7c0-2.6,0.4-4.9,1.3-7c0.9-2.1,2.1-4,3.6-5.5c1.5-1.5,3.2-2.6,5.3-3.6c2.1-0.9,4.3-1.3,6.6-1.3H226 l11.3-18.6h-40.1c-4.9,0-9.6,0.8-13.8,2.4s-7.9,4.1-10.9,7.2c-3,3.2-5.5,6.8-7.2,11.1c-1.7,4.3-2.6,9-2.6,14.3s0.9,10,2.6,14.5 c1.7,4.5,4.1,8.3,7.2,11.7c3,3.2,6.6,5.8,10.9,7.7c4.1,1.9,8.9,2.8,13.8,2.8h28.6L237.9,350.9z" />
    <path d="M144.5,367.9c3-1.1,5.7-2.6,7.7-4.5c2.1-2.1,3.4-4.5,4.3-7.3c0.9-3,1.3-6.2,1.3-9.8c0-6.6-1.9-11.5-5.5-14.7 c-3.6-3.2-9-4.9-16-4.9h-31.1c-2.1,0-3.6-0.4-4.5-1.1s-1.5-2.1-1.5-4c0-1.9,0.6-3.2,1.5-4c0.9-0.8,2.4-1.1,4.5-1.1h39.6l11.3-18.6 h-49.5c-4.3,0-8.1,0.6-11.1,1.7c-3,1.1-5.7,2.6-7.5,4.5c-1.9,1.9-3.4,4.3-4.3,7c-0.9,2.8-1.3,5.8-1.3,9.2c0,3,0.4,5.8,1.3,8.5 c0.8,2.4,2.1,4.7,4,6.4c1.7,1.7,4,3.2,6.6,4.1c2.6,0.9,5.8,1.5,9.6,1.5h31.1c2.1,0,3.6,0.4,4.5,1.1c0.9,0.8,1.5,2.1,1.5,4 c0,1.9-0.6,3.2-1.5,4c-0.9,0.8-2.4,1.3-4.5,1.3H91.5l-11.3,18.5h53.1C137.7,369.6,141.3,369,144.5,367.9z" />
    <path d="M144.5,367.9c3-1.1,5.7-2.6,7.7-4.5c2.1-2.1,3.4-4.5,4.3-7.3c0.9-3,1.3-6.2,1.3-9.8c0-6.6-1.9-11.5-5.5-14.7 c-3.6-3.2-9-4.9-16-4.9h-31.1c-2.1,0-3.6-0.4-4.5-1.1s-1.5-2.1-1.5-4c0-1.9,0.6-3.2,1.5-4c0.9-0.8,2.4-1.1,4.5-1.1h39.6l11.3-18.6 h-49.5c-4.3,0-8.1,0.6-11.1,1.7c-3,1.1-5.7,2.6-7.5,4.5c-1.9,1.9-3.4,4.3-4.3,7c-0.9,2.8-1.3,5.8-1.3,9.2c0,3,0.4,5.8,1.3,8.5 c0.8,2.4,2.1,4.7,4,6.4c1.7,1.7,4,3.2,6.6,4.1c2.6,0.9,5.8,1.5,9.6,1.5h31.1c2.1,0,3.6,0.4,4.5,1.1c0.9,0.8,1.5,2.1,1.5,4 c0,1.9-0.6,3.2-1.5,4c-0.9,0.8-2.4,1.3-4.5,1.3H91.5l-11.3,18.5h53.1C137.7,369.6,141.3,369,144.5,367.9z" />
    <path d="M57.3,337.9c0,2.4-0.4,4.7-0.9,6.4c-0.6,1.7-1.5,3.2-3,4.3c-1.3,1.1-3.2,2.1-5.5,2.4 c-2.3,0.6-5.1,0.8-8.5,0.8c-4.5,0-8.3-0.2-11.1-0.8c-2.8-0.4-5.1-1.3-6.6-2.4c-1.5-1.1-2.6-2.6-3.2-4.3c-0.6-1.7-0.9-4-0.9-6.6 v-40.1H0v40.1c0,5.5,0.8,10.2,2.3,14.3c1.5,4.1,4,7.3,7.2,10.2c3.2,2.6,7.3,4.7,12.2,6s10.9,2.1,17.7,2.1 c23.7,0,35.6-10.9,35.6-32.6v-40.1H57.3V337.9z" />
    <path d="M57.3,337.9c0,2.4-0.4,4.7-0.9,6.4c-0.6,1.7-1.5,3.2-3,4.3c-1.3,1.1-3.2,2.1-5.5,2.4 c-2.3,0.6-5.1,0.8-8.5,0.8c-4.5,0-8.3-0.2-11.1-0.8c-2.8-0.4-5.1-1.3-6.6-2.4c-1.5-1.1-2.6-2.6-3.2-4.3c-0.6-1.7-0.9-4-0.9-6.6 v-40.1H0v40.1c0,5.5,0.8,10.2,2.3,14.3c1.5,4.1,4,7.3,7.2,10.2c3.2,2.6,7.3,4.7,12.2,6s10.9,2.1,17.7,2.1 c23.7,0,35.6-10.9,35.6-32.6v-40.1H57.3V337.9z" />
    <path d="M133.2,378.6c18.3,18.6,38.8,36.9,63.7,53.9v-38.2c-6.4-5.1-12.4-10.4-18.3-15.6H133.2z" />
    <path d="M123.6,288.4c-2.3-22,5.1-40.1,17.7-52.7c34.1-34.5,98.1-16.8,96.6,32.6c0,0.2,98.3-68.2-20.3-116.6 c29.6-39.4-57.5-70.6-70.6-74.4l0,0l0,0c28.8-17.1,46.5-31.8,49.9-34.5V0c-21.7,21.1-85.9,71.2-171.4,91.9 c0,0-9.4,94.2,43.5,196.5H123.6z M128.8,151.1l10.4-32.2l10.2,32.2h34.5l-27.7,20.7l10.7,33.2l-27.7-20.3l-27.7,20.3l10.9-33.2 l-28.1-20.7H128.8z" />
  </svg>
);

LogoIcon.displayName = 'LogoIcon';

LogoIcon.defaultProps = {
  className: ''
};

export default LogoIcon;
