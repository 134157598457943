// @flow
import React from 'react';
import type { Node } from 'react';

import LogoIcon from '../icons/LogoIcon';

import styles from './Header.module.scss';

const { REACT_APP_TRAINING_APP_URL: trainingUrl } = process.env;

const Header = (): Node => (
  <header className={styles.header}>
    <a href={trainingUrl || ''}>
      <LogoIcon className={styles.logo} />
    </a>
  </header>
);

Header.displayName = 'Header';

export default Header;
