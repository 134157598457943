// @flow
import { CALL_API } from 'redux/middleware/sagas/api';
import type { CallApiReturnType } from 'redux/middleware/sagas/api';
import { FETCH, SET, FAIL } from './eventActions';
import type { ActionI } from 'redux/interfaces/ActionI';
import type { ErrorI } from 'redux/interfaces/api/ErrorI';
import type { EventI as APIEventI } from 'redux/interfaces/api/EventI';

// action creators
export type FetchEventI = {
  payload: {
    id: string
  }
} & ActionI;

export const fetchEvent = (id: string): FetchEventI => ({
  type: FETCH,
  payload: {
    id
  }
});

export type SetEventI = {
  payload: APIEventI,
  meta: {
    receivedAt: number
  }
} & ActionI;

export const setEvent = (json: APIEventI): SetEventI => ({
  type: SET,
  payload: json,
  meta: {
    receivedAt: Date.now()
  }
});

export type FetchEventFailureI = {
  payload: Array<ErrorI>,
  error: boolean
} & ActionI;

export const fetchEventFailure = (
  errors: Array<ErrorI>
): FetchEventFailureI => ({
  type: FAIL,
  payload: errors,
  error: true
});

// Async Actions
export const getEvent = (id: string): CallApiReturnType => ({
  type: CALL_API,
  payload: {
    method: 'GET',
    endpoint: `/api/training/events/${id}`,
    params: [id],
    actions: {
      request: fetchEvent,
      success: setEvent,
      failure: fetchEventFailure
    },
    isRedirectOnError: true
  }
});
