// @flow

export type NEW_USER = {
  id: string,
  userToken: {
    token: string,
    ttl: number
  }
};

export const CREATE_USER_MUTATION = `mutation createUserIdentity($input: UserIdentityCreateInput!) {
  createUserIdentity(input: $input) {
    id
    userToken {
      token
      ttl
    }
  }
}`;

export const UPDATE_PASSWORD_MUTATION = `mutation updateUserIdentity($id: ID!, $password: String!) {
  updateUserIdentity (id:$id, input: {password: $password}) {
    id
    name
  }
}`;
