// @flow
import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware as createRouterMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import sagaMonitor from 'redux-saga';
import type { History } from 'history';
import * as Sentry from '@sentry/react';

import createRootReducer from './modules/reducer';

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

function configureStore(history: History) {
  /* Send state/actions to Sentry
  To apply Sentry to Redux, use Sentry.createReduxEnhancer at the same place that you initialize your Redux store.
 See: https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/redux/
 */
  const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    // Send state on every state change (this might be overkill)...
    stateTransformer: (state) => {
      // Set state and remove large data
      const transformedState = {
        ...state,
        // You should also remove large data that is irrelevant to debugging to not clutter your Sentry issues
        giganticState: null
      };

      return transformedState;
    }
  });

  const routerMiddleware = createRouterMiddleware(history);
  const sagaMiddleware = createSagaMiddleware({ sagaMonitor });
  const middleWares = [routerMiddleware, sagaMiddleware];

  const enhancers = [
    applyMiddleware(...middleWares),
    compose(sentryReduxEnhancer)
  ];

  return {
    ...createStore(createRootReducer(history), composeEnhancer(...enhancers)),
    runSaga: sagaMiddleware.run
  };
}

export default configureStore;
