// @flow
import { CALL_API } from 'redux/middleware/sagas/api';
import type { CallApiReturnType } from 'redux/middleware/sagas/api';
import type { ActionI } from 'redux/interfaces/ActionI';
import type { ErrorI } from 'redux/interfaces/api/ErrorI';
import type { CourseI as APICourseI } from 'redux/interfaces/api/CourseI';

import { FETCH, SET, FAIL } from './courseActions';

// action creators
export type FetchCourseI = {
  payload: {
    id: string
  }
} & ActionI;

export const fetchCourse = (id: string): FetchCourseI => ({
  type: FETCH,
  payload: {
    id
  }
});

export type SetCourseI = {
  payload: APICourseI,
  meta: {
    receivedAt: number
  }
} & ActionI;

export const setCourse = (json: APICourseI): SetCourseI => ({
  type: SET,
  payload: json,
  meta: {
    receivedAt: Date.now()
  }
});

export type FetchCourseFailureI = {
  payload: Array<ErrorI>,
  error: boolean
} & ActionI;

export const fetchCourseFailure = (
  errors: Array<ErrorI>
): FetchCourseFailureI => ({
  type: FAIL,
  payload: errors,
  error: true
});

// Async Actions
export const getCourse = (id: string): CallApiReturnType => ({
  type: CALL_API,
  payload: {
    method: 'GET',
    endpoint: `/api/training/courses/${id}?include=curriculum`,
    params: [id],
    actions: {
      request: fetchCourse,
      success: setCourse,
      failure: fetchCourseFailure
    },
    isRedirectOnError: true
  }
});
