// @flow
import type { Saga } from 'redux-saga';
import { fork, take } from 'redux-saga/effects';

import enterEmailJourneySaga from 'redux/middleware/sagas/enterEmailJourneySaga';
import { BUY_CLASS_SUCCESS } from './payment';

export function* onBuyClassSuccess(): Saga<void> {
  for (;;) {
    yield take(BUY_CLASS_SUCCESS);
    yield fork(enterEmailJourneySaga);
  }
}
