// @flow
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import type { RouterState, LocationState } from 'connected-react-router';
import type { History } from 'history';
import type { StateI as CheckoutState } from './checkout/checkout';
import type { StateI as EntityState } from './entity/entityReducer';
import type { StateI as PaymentState } from './payment/payment';
import type { StateI as RegisterState } from './register/register';
import type { StateI as UserState } from './user/userReducer';

import checkout from './checkout/checkout';
import entity from './entity/entityReducer';
import payment from './payment/payment';
import register from './register/register';
import user from './user/userReducer';

export type StateI = {
  router: RouterState<LocationState>,
  checkout: CheckoutState,
  entity: EntityState,
  payment: PaymentState,
  register: RegisterState,
  user: UserState
};

const reducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    checkout,
    entity,
    payment,
    register,
    user
  });

export default reducer;
