// @flow
import { handleActions } from 'redux-actions';

import { APP_NAMESPACE } from 'shared/constants/application';
import {
  NONE,
  LOADING,
  LOADED,
  NOT_FOUND,
  ERROR
} from 'shared/constants/status';
import type { ActionI } from 'redux/interfaces/ActionI';
import { entityTypes } from './types';
import type { ErrorI } from 'redux/interfaces/api/ErrorI';
import type { EntityRecordI } from './EntityRecord';
import EntityRecord from './EntityRecord';
// import event actions
import type {
  FetchEventI,
  SetEventI,
  FetchEventFailureI
} from 'redux/modules/event/eventActionCreators';
import {
  FETCH as FETCH_EVENT,
  SET as SET_EVENT,
  FAIL as EVENT_FAIL
} from 'redux/modules/event/eventActions';
// import course actions
import type {
  FetchCourseI,
  SetCourseI,
  FetchCourseFailureI
} from 'redux/modules/course/courseActionCreators';
import {
  FETCH as FETCH_COURSE,
  SET as SET_COURSE,
  FAIL as COURSE_FAIL
} from 'redux/modules/course/courseActions';
// import occurrence actions
import type {
  FetchOccurrenceI,
  SetOccurrenceI,
  FetchOccurrenceFailureI
} from 'redux/modules/occurrence/occurrenceActionCreators';
import {
  FETCH as FETCH_OCCURRENCE,
  SET as SET_OCCURRENCE,
  FAIL as OCCURRENCE_FAIL
} from 'redux/modules/occurrence/occurrenceActions';
import { parseEventJsonToEntity } from './parseEventJsonToEntity';
import { parseCourseJsonToEntity } from './parseCourseJsonToEntity';
import { parseOccurrenceJsonToEntity } from './parseOccurrenceJsonToEntity';

const namespace: string = `${APP_NAMESPACE}/clear`;

// Actions
export const CLEAR: string = `${namespace}/CLEAR`;

// Action Creators
export const clear = (): ActionI => ({
  type: CLEAR
});

// Initial State
export type StateI = {
  type: string,
  id: string,
  status: string,
  occurrenceStatus: string,
  errors: Array<ErrorI>,
  data: EntityRecordI
};

const initialState: StateI = {
  type: '',
  id: '',
  status: NONE,
  occurrenceStatus: NONE,
  errors: [],
  occurrenceErrors: [],
  // $FlowFixMe, prop check is unnecessary, as immutable record always returns all props
  data: new EntityRecord()
};

// Reducer
const entity = handleActions(
  {
    [FETCH_EVENT]: (
      state: StateI,
      { payload: { id } }: FetchEventI
    ): StateI => ({
      ...state,
      type: entityTypes.EVENT,
      id,
      status: LOADING
    }),
    [SET_EVENT]: (state: StateI, { payload }: SetEventI): StateI => ({
      ...state,
      status: LOADED,
      data: parseEventJsonToEntity(payload)
    }),
    [EVENT_FAIL]: (state: StateI, { payload }: FetchEventFailureI): StateI => ({
      ...state,
      status: payload.length && payload[0].status === 404 ? NOT_FOUND : ERROR,
      errors: payload
    }),
    [FETCH_COURSE]: (
      state: StateI,
      { payload: { id } }: FetchCourseI
    ): StateI => ({
      ...state,
      type: entityTypes.CLASS,
      id,
      status: LOADING
    }),
    [SET_COURSE]: (state: StateI, { payload }: SetCourseI): StateI => ({
      ...state,
      status: LOADED,
      data: parseCourseJsonToEntity(payload, state.data)
    }),
    [COURSE_FAIL]: (
      state: StateI,
      { payload }: FetchCourseFailureI
    ): StateI => ({
      ...state,
      status: payload.length && payload[0].status === 404 ? NOT_FOUND : ERROR,
      errors: payload
    }),
    [FETCH_OCCURRENCE]: (state: StateI, action: FetchOccurrenceI): StateI => ({
      ...state,
      occurrenceStatus: LOADING
    }),
    [SET_OCCURRENCE]: (state: StateI, { payload }: SetOccurrenceI): StateI => {
      return {
        ...state,
        occurrenceStatus: LOADED,
        data: parseOccurrenceJsonToEntity(payload, state.data)
      };
    },
    [OCCURRENCE_FAIL]: (
      state: StateI,
      { payload }: FetchOccurrenceFailureI
    ): StateI => ({
      ...state,
      occurrenceStatus:
        payload.length && payload[0].status === 404 ? NOT_FOUND : ERROR,
      occurrenceErrors: payload
    }),
    [CLEAR]: (state: StateI): StateI => initialState
  },
  initialState
);

export default entity;
